const DownloadIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  width = 32,
  height = 32,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" />
    <path
      d="M8 19.047v1.652a3.303 3.303 0 0 0 3.31 3.303h9.38A3.314 3.314 0 0 0 24 20.692v-1.652M16 8v10.207M19.592 14.625 16.01 18.49l-3.583-3.865"
      stroke="#3B444A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadIcon;
