const NavigateIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  width = 32,
  height = 32,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" />
    <path
      d="m16.406 15.61 7.218-7.218"
      stroke="#3B444A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 10h-3a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4v-3"
      stroke="#3B444A"
      strokeLinecap="round"
    />
    <path d="M18 8h6v6" stroke="#3B444A" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default NavigateIcon;
