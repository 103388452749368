const FileCsvIcon: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
  <svg
    width="32"
    height="40"
    viewBox="0 0 32 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#zbdrvgjd6a)">
      <path
        d="m11 34.25 5 5 5-5M16 27.43v11.55M11.58 30.83H4.5V.75h15.04l7.96 7.96v22.12h-7.08"
        stroke="#3B444A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 8.71h-7.96V.75"
        stroke="#3B444A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 12H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2z"
        fill="#3B444A"
      />
      <path
        d="M5.52 18.5c0-2.1 1.59-3.54 3.59-3.54 1.56 0 2.43.82 2.91 1.67l-1.25.61c-.29-.55-.9-1-1.66-1-1.19 0-2.09.95-2.09 2.25s.9 2.25 2.09 2.25c.76 0 1.38-.43 1.66-1l1.25.61c-.49.85-1.36 1.68-2.91 1.68-2 0-3.59-1.44-3.59-3.54v.01zM13.75 19.839c.48.5 1.23.92 2.18.92.8 0 1.19-.38 1.19-.77 0-.51-.59-.69-1.39-.87-1.12-.26-2.55-.56-2.55-2.09 0-1.14.98-2.06 2.6-2.06 1.09 0 1.99.33 2.67.95l-.81 1.07c-.55-.51-1.29-.75-1.96-.75-.67 0-1.01.29-1.01.7 0 .46.57.6 1.37.79 1.13.26 2.56.59 2.56 2.11 0 1.25-.89 2.2-2.74 2.2-1.31 0-2.26-.44-2.9-1.09l.8-1.11h-.01zM22.01 21.918l-2.65-6.84h1.66l1.9 5.26 1.89-5.26h1.66l-2.64 6.84H22h.01z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="zbdrvgjd6a">
        <path fill="#fff" d="M0 0h32v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FileCsvIcon;
